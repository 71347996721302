import { css } from './theme';
import normalize from './normalize';
import colors from './colors';
import { BRAND, isMarketGB } from '../constants/main';
import { default as mainAC } from './AC/main';
import { default as mainOV } from './OV/main';
import { default as mainOV_GB } from './OV/GB/main';
import { default as mainAP } from './AP/main';
import { default as mainDS } from './DS/main';
import text from './text';
import { GridStyled } from '@components/Grid/GridStyled';
import { config } from 'react-styled-flexboxgrid';
import { rem } from 'polished';
import { publicAssets } from '../utils/url.utils';

import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";

const brandMain = (() => {
    if (BRAND === 'OV') return isMarketGB ? mainOV_GB : mainOV;
    else if (BRAND === 'AP') return mainAP;
    else if (BRAND === 'DS') return mainDS;
    else if (BRAND === 'AC') return mainAC;
    else return {};
})();

const dimensions = ['xs', 'sm', 'md', 'lg'] as const;

const main = css`
    ${normalize}
    ${colors}
    ${text}

    * {
        outline: none;
        box-sizing: border-box;
    }

    *::after,
    *::before {
        box-sizing: border-box;
    }

    html {
        font-family: ${({ theme }) => theme.fonts.fontBase};
    }

    body {
        overflow-x: hidden;
    }

    #__next,
    body {
        width: 100%;
    }

    #nprogress .bar {
        background: ${({ theme }) => theme.colors.primary};
    }

    ${GridStyled} {
        width: auto;

        ${({ theme }) =>
            dimensions.map(
                (dimension) =>
                    (config(theme.flexboxgrid) as any).container[dimension] &&
                    config(theme.flexboxgrid).media[dimension]`
            width: auto;
            max-width: ${(config(theme.flexboxgrid) as any).container[dimension]}rem;
        `
            )}
    }

    .currencyLabel {
        &__suffix {
            &--TTC {
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                padding-left: ${rem('5px')};

                &-monthly {
                    font-size: ${({ theme }) => theme.fontSizes.textBase};
                    color: inherit;
                }
            }

            &--PM {
                padding-left: ${rem('5px')};
                font-size: ${({ theme }) => theme.fontSizes.textBase};
            }

            &--OTR {
                padding-left: ${rem('5px')};
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
        }
    }

    #firstname_label,
    #lastname_label {
        display: block !important;
    }

    *[role='button'],
    *[role='slider'],
    a[href],
    button,
    input,
    .Select__control {
        &:not([tabindex='-1']) {
            &:focus-visible {
                outline: 1px dotted ${({ theme }) => theme.colors.accessibilityOutlinecolor};
                outline-offset: 3px;
            }
        }
    }

    ${brandMain}

    .bu-no-scroll {
        overflow: hidden;
    }
    .${BRAND} {
        .buorg {
            height: 100%;
            background-color: transparent !important;
            box-shadow: none !important;
            color: #fff !important;
            font-family: 'Peugeot', 'Arial', 'sans-serif' !important;
            text-align: left !important;
            animation: none !important;
            pointer-events: none;
            z-index: 10000001;
        }

        .buorg::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.8;
        }

        .buorg-pad {
            width: 1140px;
            margin: 0 auto;
            padding: 30px 15px !important;
        }

        .buorg-buttons {
            margin-top: 20px;
            text-align: left !important;
        }

        #buorgig,
        #buorgul,
        #buorgpermanent {
            width: 290px;
            height: 50px;
            margin: 0 !important;
            padding: 16px 10px !important;
            border-radius: 0 !important;
            box-shadow: none !important;
            font-size: 14px;
            line-height: 1.4;
            text-align: center;
            text-transform: uppercase;
            pointer-events: auto;
        }
    }

    @font-face {
        font-family: 'revicons-override';
        fallback: fallback;
        src: url(${publicAssets('/fonts/revicons.woff')}) format('woff'),
            url(${publicAssets('/fonts/revicons.ttf')}) format('ttf'),
            url(${publicAssets('/fonts/revicons.eot')}) format('embedded-opentype');
    }

    @font-face {
        font-family: 'Notification-override';
        src: url(${publicAssets('/fonts/notification.woff')}) format('woff'),
            url(${publicAssets('/fonts/notification.ttf')}) format('ttf'),
            url(${publicAssets('/fonts/notification.eot')}) format('embedded-opentype');
    }

    .notification:before {
        font-family: 'Notification-override' !important ;
    }

    .sectionTitle {
        color: ${({ theme }) => theme.colors.grey5};
        display: block;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        margin-bottom: 20px;
    }

    .pac-container {
        z-index: 2147483647 !important;
    }
  
    .ReactModal__Overlay {
        z-index: 9999;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    div#chat-widget-container,
    body #web-messenger-container {
        z-index: 999 !important;
    }
  
  .agreement1 h4 {
    display: none !important;
  }
  
  .agreement2 h4 {
    display: none !important;
  }
  
  .agreement3 h4 {
    display: none !important;
  }
`;

export const invisibleScrollbars = css`
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export default main;
