export const ENGINE_DIESEL = 'Diesel';
export const ENGINE_ESSENCE = 'Essence';
export const ENGINE_HYBRID_ESSENCE = 'Hybride-Essence';

export const TRANSMISSION_AUTOMATIQUE = 'Automatique';
export const TRANSMISSION_MANUELLE = 'Manuelle';

import { IFilter } from '../carList/carList.types';
import {
    IPriceRange,
    IFilterRule,
    IFilterCategory,
    ConfigurableParentNames,
    StockParentNames,
} from '../../redux/filters/filter.duck.interface';
import { StringMapping } from '../../interfaces/StringMapping';
import {
    CURRENCY,
    PRICES_FORMAT,
    FINANCE,
    OperatorTypes,
    MAX_PRICE,
    MIN_PRICE,
    PRICES,
    COLOR_GROUPS,
    STOCK_TYPE,
    SORT,
} from './filters';
import { TBudgetType } from '../../interfaces/IFilters';
import { BASE_PRICE, MONTHLY_PRICES, AMOUNT, GLOBAL, FUEL } from '../core/sdk/constants/sdkFields';
import { MONTHLY_PRICE_AMOUNT } from '../carList/car';
import { IAggregation, IAggregations } from '../stock/models/stockItems.service.model';
import { getLanguageLongString, getLanguage } from '@utils/getLanguageLongString';
import { PaymentJourneyTypes } from '../../partExchange/interfaces/Default';

export const createFilterEngine = (engines: string[]): IFilter => {
    return {
        name: FUEL,
        nesting: [FUEL],
        operation: OperatorTypes.IN,
        value: engines,
        parent: null,
    };
};

export const createFilterTransmission = (transmissions: string[]): IFilter => {
    return {
        name: 'gearbox.specs.label.tr',
        nesting: ['gearbox', 'specs', 'label'],
        operation: OperatorTypes.IN,
        value: transmissions,
        parent: null,
    };
};

export const createFilterBudget = (value: IPriceRange, budget: TBudgetType): IFilter => {
    if (!value) return null;
    if (budget === FINANCE) {
        return {
            name: `${PRICES}.${MONTHLY_PRICES}.${AMOUNT}.${GLOBAL}`,
            nesting: [PRICES, MONTHLY_PRICES, AMOUNT],
            operation: OperatorTypes.BETWEEN,
            value: { from: value.min, to: value.max },
            parent: null,
        };
    }
    return {
        name: `${PRICES}.${BASE_PRICE}.${GLOBAL}`,
        nesting: [PRICES, BASE_PRICE],
        operation: OperatorTypes.BETWEEN,
        value: { from: value.min, to: value.max },
        parent: null,
    };
};

export const createFilterPayload = (type: ConfigurableParentNames, value: any, budget: TBudgetType): IFilter => {
    if (type === 'fuel') {
        return createFilterEngine(value);
    } else if (type === 'gearbox') {
        return createFilterTransmission(value);
    } else if (type === 'prices') {
        return createFilterBudget(value, budget);
    }
};

export const getStockFilters = (filters: IAggregations): IFilterRule[] => {
    const buttons: IFilterRule[] = [
        {
            name: PRICES,
            parent: PRICES,
            displayName: `filters.category.prices.monthlyPrices.amount`,
        },
        {
            name: SORT,
            parent: SORT,
            type: null,
            order: null,
        },
        {
            name: STOCK_TYPE,
            parent: STOCK_TYPE,
        },
    ];

    for (const type of Object.keys(filters)) {
        const value = (filters as any)[type] as IAggregation;

        if (Array.isArray(value)) {
            value.forEach(({ hexCode, value: code }, index) => {
                const parent = type.split('.').shift() as StockParentNames;
                const name = value[index].labels[getLanguageLongString(getLanguage())];

                if (!name) return;

                buttons.push({
                    name: `${parent}.${name}`,
                    parent,
                    displayName: name,
                    label: name,
                    value: false,
                    code,
                    ...(parent === COLOR_GROUPS && { hexCode }),
                });
            });
        }
    }

    return buttons;
};

export const getFilters = (filters: StringMapping<string[] | IPriceRange>): IFilterRule[] => {
    const buttons: IFilterRule[] = [];
    Object.keys(filters).forEach((type) => {
        const value = filters[type];
        if (Array.isArray(value)) {
            // TODO: Finish this
            if (type === CURRENCY || type === PRICES_FORMAT) {
                return;
            }
            value.forEach((_, i) => {
                const parent = type.split('.')[0] as ConfigurableParentNames;
                buttons.push({
                    name: `${parent}.${value[i]}`,
                    parent,
                    displayName: value[i],
                    value: false,
                });
            });
        } else {
            buttons.push({
                name: 'prices',
                parent: 'prices',
                displayName: type,
            });
        }
    });

    return buttons;
};

export const getPriceRange = (filters: StringMapping<string[] | IPriceRange>, budget: TBudgetType) => {
    const price = Object.keys(filters).find(
        (type) =>
            (budget === FINANCE && type === `${PRICES}.${MONTHLY_PRICES}.${MONTHLY_PRICE_AMOUNT}`) ||
            type === `${PRICES}.${BASE_PRICE}`
    );
    const value = filters[price] as IPriceRange;
    if (value) {
        return {
            min: Math.floor(value.min),
            max: Math.ceil(value.max),
        };
    } else {
        return;
    }
};

export const getFilterCategories = (filters: IAggregations | StringMapping<string[] | IPriceRange>) => {
    const buttons: IFilterCategory[] = [];
    Object.keys(filters).forEach((type) => {
        const shouldAddPriceButton =
            [MIN_PRICE, MAX_PRICE].includes(type) && !buttons.find(({ name }) => name === PRICES);

        if (shouldAddPriceButton) {
            return buttons.push({
                name: PRICES,
                displayName: PRICES,
                filters: [],
            });
        }

        if ([CURRENCY, PRICES_FORMAT, MIN_PRICE, MAX_PRICE].includes(type)) {
            return;
        }

        const parent = type.split('.')[0] as ConfigurableParentNames | StockParentNames;

        buttons.push({
            name: parent,
            displayName: parent,
            filters: [],
        });
    });

    buttons.push({
        name: STOCK_TYPE,
        displayName: STOCK_TYPE,
        filters: [],
    });

    return buttons;
};
