import { formatDateByLang } from '@utils/Date.utils';
import { ICarImportInput } from 'src/interfaces/Car';
import { IConfigurableCar, IConfigurableCarExtended, IDeal } from '../interfaces/Car';
import { CarJourneyType, DealService } from '../services';
import { ICarDetail } from '../redux/carDetails/carDetails.duck.interface';
import { getSessionIdCookie, setSessionIdCookie } from '@utils/Session.utils';
import {BASKET_STEPS, isProd, MARKET, MARKETS} from '../constants/main';
import { TBudgetType } from '../interfaces/IFilters';
import { ISetConfigurationExtra } from 'src/services/deal/deal.types';
import { IDispatchValue } from '../partExchange/interfaces/IStore';
import { IFinanceQuote } from '../services/calculateSummary/calculateSummary.types';
import CarDetailsDuck from '../redux/carDetails/carDetails.duck';
import { syncPartExchange } from '../partExchange/service/ApiService';
import ConfiguratorDuck from '../redux/configurator/configurator.duck';
import { processMopData } from '../partExchange/utility/general';
import { Dispatch } from 'redux';

export const createDeal = async (
    carDetails: IConfigurableCar | ICarDetail,
    paymentJourney: TBudgetType,
    extra: ISetConfigurationExtra,
    status?: string | null
): Promise<{ deal: IDeal; financeQuote: IFinanceQuote }> => {
    let deal: any;

    const configId = (carDetails as ICarDetail).stock
        ? (carDetails as ICarDetail).orders[0].uuid
        : (carDetails as ICarDetail)._id ?? (carDetails as ICarDetail).externalId;

    const { headers, data } = await DealService.setConfiguration(
        getSessionIdCookie(),
        configId,
        paymentJourney,
        carDetails.stock ? CarJourneyType.STOCK : CarJourneyType.CONFIGURABLE,
        extra,
        null,
        null,
        status
    );

    if (headers['x-auth-token']) {
        setSessionIdCookie(headers['x-auth-token']);
    }

    if (data?.success) {
        deal = data.data.deal ?? {};
    }

    return {
        deal,
        financeQuote: data.data.financeQuote,
    };
};

export const parseDealDataToConfigurableCarDetail = (
    deal: IDeal<IConfigurableCarExtended>
): Omit<IConfigurableCar, '_id'> => {
    const {
        fullProductConfiguration: {
            bodyStyle,
            brand,
            engine,
            engineGearboxFuelSlug,
            exteriorColour,
            exteriorColourSlug,
            externalId,
            fuel,
            fuelCode,
            fees,
            gearbox,
            dealers,
            id,
            images,
            interiorColour,
            interiorColourSlug,
            isDefaultConfiguration,
            language,
            lcdv16,
            leadTime,
            leadTimeDate,
            leadTimeProductionYear,
            market,
            model,
            nameplate,
            nameplateBodyStyle,
            nameplateBodyStyleSlug,
            offers,
            options,
            prices,
            specPack,
            specPackSlug,
            standardFeatures,
            grBodyStyle,
            grGearbox,
            maxLeadTime,
            maxLeadTimeDate,
            maxLeadTimeProductionYear,
            possibleOptions,
            pricesV2,
            promotionalText,
            seriesEquipment,
            stock,
            stockSpecificFields,
            tariffCode,
            tariffStartDate,
            technicalSpecifications,
            title,
            vehicleUseId,
            visualCode,
            characteristics,
            accessories,
            importInputs,
            orders,
        },
    } = deal;

    return {
        bodyStyle,
        brand,
        engine,
        engineGearboxFuelSlug,
        exteriorColour,
        exteriorColourSlug,
        externalId,
        fuel,
        fuelCode,
        dealers,
        gearbox,
        id,
        images,
        fees,
        interiorColour,
        interiorColourSlug,
        language,
        lcdv16,
        leadTime,
        leadTimeDate,
        market,
        maxLeadTime,
        maxLeadTimeDate,
        model,
        nameplate,
        nameplateBodyStyleSlug,
        offers,
        orders,
        options,
        prices,
        pricesV2,
        specPack,
        specPackSlug,
        standardFeatures,
        stock,
        stockSpecificFields,
        technicalSpecifications,
        title,
        accessories,
        importInputs,
        characteristics,
        extraFields: {
            grBodyStyle,
            grGearbox,
            isDefaultConfiguration,
            leadTimeDate,
            leadTimeProductionYear,
            maxLeadTimeDate,
            maxLeadTimeProductionYear,
            nameplateBodyStyle,
            possibleOptions,
            pricesV2,
            promotionalText,
            seriesEquipment,
            tariffCode,
            tariffStartDate,
            vehicleUseId,
            visualCode,
        },
    };
};

export const getFormattedOfferValidityDate = (importInputs: ICarImportInput): string => {
    const regulatorChanges = importInputs?.offerValidityDate;

    return regulatorChanges ? formatDateByLang(regulatorChanges) : '';
};

export const getEcologicalBonus = (
    carDetails: IConfigurableCar
): {
    hasEcologicalBonus: boolean;
    ecologicalBonusValue: number;
    ecologicalBonusTitleKey: string;
    offerValidityDate?: string;
} => {
    let hasEcologicalBonus = false;
    let ecologicalBonusValue = 0;
    let ecologicalBonusTitleKey;

    const ecoBonus = carDetails?.importInputs?.ecologicalBonus;
    const offerValidityDate = carDetails?.importInputs?.offerValidityDate;
    const brandEcoBonus = carDetails?.importInputs?.brandEcologicalBonus;

    if (ecoBonus > 0 || brandEcoBonus > 0) {
        hasEcologicalBonus = true;

        // scrappage or srotovne bonus
        if (MARKET === MARKETS.IT) {
            // IF GOV Eco-bonus > 0 & NSC brand Eco-bonus > 0
            if (ecoBonus > 0 && brandEcoBonus > 0) {
                ecologicalBonusValue = ecoBonus + brandEcoBonus;
                ecologicalBonusTitleKey = 'basket.ecoAndBrandEcoBonusTitle';
            }
            // IF NSC Eco-bonus > 0 & GOV Eco-bonus <= 0 or NULL
            if (ecoBonus > 0 && (!brandEcoBonus || brandEcoBonus <= 0)) {
                ecologicalBonusValue = ecoBonus;
                ecologicalBonusTitleKey = 'basket.ecoBonusTitle';
            }
            // IF GOV Eco-bonus > 0 & NSC brand Eco-bonus <= 0 or NULL
            if (brandEcoBonus > 0 && (!ecoBonus || ecoBonus <= 0)) {
                ecologicalBonusValue = brandEcoBonus;
                ecologicalBonusTitleKey = 'basket.brandEcoBonusTitle';
            }
        } else {
            ecologicalBonusValue = ecoBonus;
            ecologicalBonusTitleKey = 'offerDetail.bonus.title';
        }
    }

    return { hasEcologicalBonus, ecologicalBonusValue, ecologicalBonusTitleKey, offerValidityDate };
};

export const updateBasketSteps = async (
    dispatch: React.Dispatch<IDispatchValue>,
    currentDeal: IDeal,
    step: BASKET_STEPS,
    value: string | boolean
) => {
    const updatedStep = {
        ...(step === BASKET_STEPS.PAYMENT && { hasSelectedPaymentJourney: Boolean(value) }),
        ...(step === BASKET_STEPS.FEES && value && { selectedFeesValue: value }),
        ...(step === BASKET_STEPS.PX && { selectedPxValue: value }),
    };

    dispatch(
        CarDetailsDuck.setCurrentDeal({
            ...currentDeal,
            extraFields: { ...currentDeal.extraFields, ...updatedStep },
        })
    );
    await DealService.updateDeal(
        {
            extra_field: updatedStep,
        },
        getSessionIdCookie(),
        false
    );
};

export const openC2SWidget = (c2sId: string, dispatch: Dispatch, dealId: string) => {
    setTimeout(() => {
        // @ts-ignore
        if (typeof window?.Clic2sell !== 'undefined') {
            // @ts-ignore
            const c2s = new window.Clic2sell({
                env: isProd ? 'prod' : 'ppr',
                language: process.env.NEXT_PUBLIC_LANGUAGE,
                folderId: c2sId,
            });

            c2s.open();

            const callBack = async () => {
                const removeListener = () => {
                    document.removeEventListener('click', callBack);
                };

                const element = document.getElementById('c2smodal');
                if (!element) {
                    console.log('C2S WIDGET WAS CLOSED');
                    const { data } = await syncPartExchange(dealId);
                    dispatch(ConfiguratorDuck.setPxState(processMopData(data?.data)));
                    removeListener();
                }
            };

            document.addEventListener('click', callBack);
        }
    }, 1000);
};
