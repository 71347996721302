import { AxiosPromise, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import pxInstance from '../api/pxConfig';
import {
    PX_CREATE_ENDPOINT,
    PX_DELETE_ENDPOINT,
    PX_SYNC_ENDPOINT,
    PX_UPDATE_ENDPOINT,
    WIDGET_PARAMS_ENDPOINT,
} from '../constants/api';
import { PX_STATUSES_CONTINUE, PX_STATUSES_REFUSED, PxErrorCodes, pxWidgetConfig } from '../constants/config';
import {
    AUTOBIZ_WIDGET_URL_V1,
    AUTOBIZ_WIDGET_URL_V2,
    DependencyType,
} from '../../utils/externalDependencies/dependencies';
import { PART_EXCHANGE_ERROR_NULL } from '../constants/errors';
import { IDispatchValue } from '../interfaces/IStore';
import { IPartExchangeData, IWidgetState } from '../interfaces/PxWidget';
import {
    setModalButtonEnabled,
    setPxError,
    setWidgetPartExchange,
    setWidgetTemplate,
    throwGlobalError,
} from '../store/actions';
import { captureException } from '@utils/sentry.utils';
import { processWidgetData } from './DataService';
import coreAPIService from '../../services/core/coreApi.service';
import AssetManager from '@utils/externalDependencies/AssetManager';
import { B2B_URL, B2C_URL, IS_B2C, PxVersion } from '../../constants/main';
import { addAssetScripts, getDeviceTypeByWidthOfBrowser } from '../utility/general';
import routes from '../../constants/routes';
import { IDeal } from '../../interfaces/Car';
import moment from 'moment/moment';

/**
 * Method calls widget params endpoint and applies widget data to state
 * @param dispatch
 * NOTE: tested
 */
export function getWidgetParams(
    dispatch: Dispatch<IDispatchValue<string | {} | null>>,
    widgetState: Partial<IWidgetState>,
    pxId?: string,
    mopId?: string,
    evaluateToken: boolean = false,
    pxVersion = PxVersion.v1,
    currentDeal?: IDeal,
    router?: { push: Promise<boolean>; replace: Promise<boolean>; pathname: string; query: { [key in string]: string } }
): AxiosPromise {
    const {
        co2,
        energy,
        lcdv,
        ncPrice,
        mopId: mopIdFromConfig,
        ignorePromo,
        allowPxFinalization,
    } = widgetState.config!;
    const resolvedMopId = allowPxFinalization ? mopId || mopIdFromConfig : null;
    let url = WIDGET_PARAMS_ENDPOINT;

    if (pxVersion === PxVersion.v2) {
        url = `${url}/${currentDeal?.token}`;
    }
    const widgetPromise: AxiosPromise = pxInstance.get(url, {
        params: {
            pxId,
            co2,
            energy,
            lcdv,
            ncPrice,
            mopId: resolvedMopId,
            ignorePromo,
            evaluateToken: evaluateToken === true || null,
            noUpdate: pxVersion === PxVersion.v2,
            // no way, even partExchangeId is present, we might need to call evaluation, due to the change of car, or journey...
            // noUpdate: !!widgetState.config.partExchangeId,
        },
    });
    widgetPromise.then(async ({ status, data: { widgetOptions, partExchange } }: AxiosResponse) => {
        const processedWidgetOptions = {
            ...processWidgetData(widgetOptions),
            ignorePromo: widgetOptions.ignorePromo || ignorePromo,
            ...(pxVersion === PxVersion.v2 && {
                deviceType: getDeviceTypeByWidthOfBrowser(),
                journeyMode: 'onlineWithNewCar',
                customerType: 'B2C',
                expectedDeliveryDate: moment(currentDeal?.fullProductConfiguration?.leadTimeDate).format('MM/DD/YYYY'),
                omniDefaultLink: `${IS_B2C ? B2C_URL : B2B_URL}${routes.BASKET}?dealId=${currentDeal?.token}`,
                dealId: currentDeal?.token,
                valuationToken: null,
            }),
        };

        let widgetPartExchangeData: IPartExchangeData;

        if (
            pxVersion === PxVersion.v2 &&
            (currentDeal?.partExchangeId ||
                (router?.pathname?.includes(routes.BASKET) &&
                    router?.query?.['c2sFolder'] &&
                    router?.query?.['dealId']))
        ) {
            const { data } = await syncPartExchange(currentDeal?.token);
            if (
                data?.data?.valuation?.engagementValuationApplied &&
                !PX_STATUSES_REFUSED.includes(data?.data?.statusId)
            ) {
                widgetPartExchangeData = data?.data;
                if (PX_STATUSES_CONTINUE.includes(data?.data?.statusId)) {
                    processedWidgetOptions.valuationToken = data?.valuationToken;
                }
            }
        } else {
            widgetPartExchangeData = partExchange;
        }

        // wait for already loading assets(JQuery & bootstrap) and then load autobiz js
        addAssetScripts(widgetOptions.domain, pxVersion, dispatch);

        setWidgetTemplate(dispatch, processedWidgetOptions);
        if (widgetPartExchangeData) {
            setWidgetPartExchange(dispatch, widgetPartExchangeData);
        }

        if (status === 400) setPxError(dispatch, PxErrorCodes.WRONG_CREDENTIALS);
        if (status === 500) setPxError(dispatch, PxErrorCodes.UNAVAILABLE);
    });
    return widgetPromise;
}

export function updateCompletedPXInOrder(dealId: string): AxiosPromise {
    return coreAPIService.post(`digital-api/api/part-exchanges/${dealId}/confirm`, {}, {});
}

export function syncPartExchange(dealId: string): AxiosPromise {
    try {
        const widgetPromise: AxiosPromise = pxInstance.post(`${PX_SYNC_ENDPOINT}/${dealId}`, {}, {});
        return widgetPromise;
    } catch (error) {
        // @ts-ignore
        return new Promise((resolve) => resolve());
    }
}

/**
 * Method calls create endpoint to create the part exchange with the given valuation key
 */
export function createPartExchange(widgetState: Partial<IWidgetState>, clearSession?: boolean): AxiosPromise {
    let parsedValuation: any = '';
    if ((window as any).valuation) {
        parsedValuation = JSON.parse((window as any).valuation);
    }
    const widgetPromise: AxiosPromise = pxInstance.post(
        `${PX_CREATE_ENDPOINT}/${parsedValuation.valuationKey}`,
        {},
        {
            params: { clearSession: !!clearSession },
        }
    );
    return widgetPromise;
}

export function updatePartExchange(widgetState: Partial<IWidgetState>): AxiosPromise {
    let parsedValuation: any = '';
    if ((window as any).valuation) {
        parsedValuation = JSON.parse((window as any).valuation);
    }

    // TODO Update PX_StatusID on BE APP-13149
    // widgetState.partExchangeData.statusId

    const widgetPromise: AxiosPromise = pxInstance.patch(
        `${PX_UPDATE_ENDPOINT}/${widgetState.partExchangeData!.id}/${parsedValuation.valuationKey}`,
        {},
        {
            params: {},
        }
    );
    return widgetPromise;
}

/**
 * Method calls delete endpoint to remove part exchange
 */
export function deletePartExchange(
    dispatch?: Dispatch<IDispatchValue<any>>,
    widgetState?: Partial<IWidgetState>,
    pxId?: string
): AxiosPromise {
    if (widgetState && dispatch && widgetState.partExchangeData && !widgetState.partExchangeData.id) {
        throwGlobalError(dispatch, new Error(PART_EXCHANGE_ERROR_NULL));
    }

    const widgetPromise: AxiosPromise = pxInstance.delete(
        `${PX_DELETE_ENDPOINT}/${pxId || widgetState?.partExchangeData!.id}`
    );

    return widgetPromise;
}
