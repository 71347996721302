import { createActionTypes } from '../createActionTypes';
import { IAction, Redux } from '../redux.interface';
import {
    IFilterCategory,
    IFilterDuck,
    IFilterRule,
    IFilterState,
    IGeoLocation,
    IPriceRange,
} from './filter.duck.interface';
import { TBudgetType } from '../../interfaces/IFilters';
import { SORT_RECOMMENDED, SORT_TYPE } from '../../services';
import { modifySortFilter } from '../../services/filters/utils/Filters.utils';
import { SortOrder, SortType } from '../../services/stock/models/stockItems.service.model';
import { DEFAULT_DISTANCE_OPTIONS_STEP } from '../../constants/main';
import { STOCK_TYPE } from '../../services/filters/filters';
import { IFilter } from '../../services';

const DEFAULT_DISTANCE_STEP = DEFAULT_DISTANCE_OPTIONS_STEP;

const name = 'Filters';

const initialState: IFilterState = {
    filters: [],
    filteredFilters: [],
    filterCategories: [],
    priceRange: { min: 0, max: 100000 },
    budgetType: null,
    hasLeasysSelected: false,
    wasSetBudgetType: false,
    sort: SORT_RECOMMENDED,
    geoLocation: null,
    geoLocationName: '',
    distanceRadius: null,
    dealerId: null,
};

const actionTypes: any = createActionTypes(
    {
        set_filters: 'set_filters',
        set_filtered_filters: 'set_filtered_filters',
        reset_filters: 'reset_filters',
        change_budget_type: 'change_budget_type',
        has_leasys_selected: 'has_leasys_selected',
        change_default_budget_type: 'change_default_budget_type',
        was_set_budget_type: 'was_budget_type',
        set_filter_categories: 'set_filter_categories',
        set_price_range: 'set_price_range',
        set_distance_radius: 'set_distance_radius',
        set_sorting: 'set_sorting',
        set_dealer_id: 'set_dealer_id',
        reset_geo_location_properties: 'reset_geo_location_properties',
    },
    name
);

class FiltersDuck implements IFilterDuck {
    name = name;

    reducer(state: IFilterState = initialState, action: IAction) {
        switch (action.type) {
            case actionTypes.set_price_range:
                return {
                    ...state,
                    priceRange: action.payload,
                };
            case actionTypes.set_distance_radius:
                return {
                    ...state,
                    distanceRadius: action.payload,
                };
            case actionTypes.set_filter_categories:
                return {
                    ...state,
                    filterCategories: [...action.payload],
                };
            case actionTypes.set_filters:
                let { filters } = action.payload;
                const { distanceRadius, geoLocation, geoLocationName, dealerId } = action.payload;

                if (!filters?.find((filter: IFilter) => filter.name === STOCK_TYPE)) {
                    filters = [...filters, { name: STOCK_TYPE, parent: STOCK_TYPE, value: null }];
                }

                return {
                    ...state,
                    filters,
                    geoLocation:
                        geoLocation && !isNaN(geoLocation?.lat) && !isNaN(geoLocation?.lng)
                            ? geoLocation
                            : state.geoLocation,
                    distanceRadius: distanceRadius ?? state.distanceRadius,
                    geoLocationName: geoLocationName ? geoLocationName : state.geoLocationName,
                    dealerId: dealerId ?? state.dealerId,
                };
            case actionTypes.set_filtered_filters:
                return {
                    ...state,
                    filteredFilters: [...action.payload],
                };
            case actionTypes.reset_filters:
                return {
                    ...state,
                    filters: [...action.payload],
                };
            case actionTypes.change_budget_type:
                return {
                    ...state,
                    budgetType: action.payload,
                };
            case actionTypes.has_leasys_selected:
                return {
                    ...state,
                    hasLeasysSelected: action.payload,
                };
            case actionTypes.was_set_budget_type:
                return {
                    ...state,
                    wasSetBudgetType: action.payload,
                };
            case actionTypes.change_default_budget_type:
                return {
                    ...state,
                    budgetType: action.payload,
                    wasSetBudgetType: true,
                };
            case actionTypes.set_sorting:
                return {
                    ...state,
                    sort: action.payload,
                };
            case actionTypes.set_dealer_id:
                return {
                    ...state,
                    dealerId: action.payload,
                };
            case actionTypes.reset_geo_location_properties:
                return {
                    ...state,
                    filters: modifySortFilter(state.filters, SortType.PRICE, SortOrder.ASC),
                    geoLocation: null,
                    geoLocationName: '',
                    dealerId: null,
                };
            default:
                return state;
        }
    }

    setFilters(
        filters: IFilterRule[],
        geoLocation?: IGeoLocation,
        distanceRadius?: number,
        geoLocationName?: string,
        dealerId?: string
    ): IAction {
        if (!distanceRadius && distanceRadius !== 0) distanceRadius = DEFAULT_DISTANCE_STEP;
        return {
            type: actionTypes.set_filters,
            payload: { filters, geoLocation, distanceRadius, geoLocationName, dealerId },
        };
    }

    setFilteredFilters(filters: IFilterRule[]): IAction {
        return {
            type: actionTypes.set_filtered_filters,
            payload: filters,
        };
    }

    wasSetBudgetType(type: boolean): IAction {
        return {
            type: actionTypes.was_set_budget_type,
            payload: type,
        };
    }

    resetFilters(filters: IFilterRule[]): any {
        return (dispatch: any) => {
            const newFilters = filters.map((filter) => {
                if (filter.name === 'sort') {
                    filter.type = SortType.PRICE;
                    filter.order = SortOrder.ASC;
                } else if (typeof filter.value === 'boolean') {
                    filter.value = false;
                } else {
                    filter.value = null;
                }
                return filter;
            });
            dispatch({
                type: actionTypes.reset_filters,
                payload: newFilters,
            });
            dispatch({
                type: actionTypes.set_distance_radius,
                payload: null,
            });
        };
    }

    setFilterCategories(categories: IFilterCategory[]): IAction {
        return {
            type: actionTypes.set_filter_categories,
            payload: categories,
        };
    }

    setPriceRange(priceRange: IPriceRange): IAction {
        return {
            type: actionTypes.set_price_range,
            payload: priceRange,
        };
    }

    setDistanceRadius(distanceRadius: number): IAction {
        return {
            type: actionTypes.set_distance_radius,
            payload: distanceRadius,
        };
    }

    changeBudgetType(type: string): IAction {
        return {
            type: actionTypes.change_budget_type,
            payload: type,
        };
    }

    hasLeasysSelected(type: boolean): IAction {
        return {
            type: actionTypes.has_leasys_selected,
            payload: type,
        };
    }

    changeDefaultBudgetType(type: string): IAction {
        return {
            type: actionTypes.change_default_budget_type,
            payload: type,
        };
    }

    setDealerId(id: string): IAction {
        return {
            type: actionTypes.set_dealer_id,
            payload: id,
        };
    }

    setSorting(type: SORT_TYPE): IAction {
        return {
            type: actionTypes.set_sorting,
            payload: type,
        };
    }

    resetGeoLocationProperties(): IAction {
        return {
            type: actionTypes.reset_geo_location_properties,
        };
    }

    getOwnState(state: any): IFilterState {
        return state[this.name] || initialState;
    }

    getFilters(state: any): IFilterRule[] {
        return this.getOwnState(state).filters;
    }

    getFilteredFilters(state: any): IFilterRule[] {
        return this.getOwnState(state).filteredFilters;
    }

    getFilterCategories(state: any): IFilterCategory[] {
        return this.getOwnState(state).filterCategories;
    }

    getPriceRange(state: any): IPriceRange {
        return this.getOwnState(state).priceRange;
    }

    getPaymentJourneyType(state: any): TBudgetType {
        return this.getOwnState(state).budgetType;
    }

    getSorting(state: any) {
        return this.getOwnState(state).sort;
    }

    getGeoLocation(state: Redux) {
        return this.getOwnState(state).geoLocation;
    }

    getGeoLocationName(state: Redux) {
        return this.getOwnState(state).geoLocationName;
    }

    getDistanceRadius(state: Redux) {
        return this.getOwnState(state).distanceRadius;
    }
}

export const FilterDuck: IFilterDuck = new FiltersDuck();
export default FilterDuck;
