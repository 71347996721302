import { useState, useEffect } from 'react';
import posthog from 'posthog-js';
import {
    isBrandAC,
    isBrandDS,
    isBrandOV,
    isMarketGB,
    NEXT_PUBLIC_POSTHOG_API_KEY,
    NEXT_PUBLIC_POSTHOG_DOMAIN,
} from '../constants/main';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import useCookieConsent from '@hooks/useCookieConsent';
import { PlatformType } from '../constants/enums';

const TENANT = 'tenant';

type Context = {
    brand: string;
    market: string;
    locale: string;
    channel?: string;
};

type UsePosthog = (sessionId: string | null, context: Context) => { isLoaded: boolean };

export const usePosthog: UsePosthog = (sessionId, context) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const autocapture = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_POSTHOG_IS_AUTOCAPTURE_ENABLED);
    const consentGiven = useCookieConsent();

    useEffect(() => {
        posthog.init(NEXT_PUBLIC_POSTHOG_API_KEY, {
            api_host: NEXT_PUBLIC_POSTHOG_DOMAIN,
            persistence: 'memory',
            autocapture: autocapture && (consentGiven || ((isBrandOV || isBrandAC || isBrandDS) && isMarketGB)),
            disable_session_recording: true,
            disable_persistence: true,
            loaded: () => {
                if (sessionId) {
                    posthog.identify(sessionId);
                }

                const groupKey = Object.values(context).join('-');

                posthog.group(TENANT, groupKey, context);
            },
        });

        setIsLoaded(true);
    }, [autocapture, consentGiven, context, sessionId]);

    return { isLoaded };
};

type ExperimentData = {
    trigger: string;
    page: string;
    action?: string | null;
    customer?: string | null;
    position?: string | null;
    payment?: string | null;
    vehicle?: object | null;
};
export const pushTriggerToPosthog = (data: ExperimentData) => {
    try {
        const result = posthog.capture(data.trigger, {
            page: data.page,
            action: data?.action,
            version: PlatformType.SOL,
            ...(data?.customer && { customer: data?.customer }),
            ...(data?.position && { position: data?.position }),
            ...(data?.payment && { payment: data?.payment }),
            ...(data?.vehicle && { vehicle: data?.vehicle }),
        });
    } catch (error) {
        console.error(error);
    }
};
