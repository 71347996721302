import { BrandTypes } from '../../../types/brands';
import { IPriceStock } from './stockItem.price.model';
import { IDeal } from '../../deal/deal.types';
import { IFinanceQuote } from '../../calculateSummary/calculateSummary.types';
import { IStockCar } from '../../../interfaces/Car';
import { IPriceV2 } from '../../features/featureList.types';
import { IPromotionalText } from '../../../interfaces/Price';
import { IOffer } from '../../../interfaces/IOfferItem';

// this should be move to constants
export type Culture = string;

export enum StockPriceTypes {
    FINANCED = 'financed',
    CASH = 'cash',
}

export enum Country {
    FR = 'FR',
    PT = 'PT',
}

export enum SortType {
    PROXIMITY = 'proximity',
    PRICE = 'price',
}
export enum VDStatus {
    DEMO = 'A1',
    SHOWROOM = 'B',
}

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

interface ICoordinates {
    lat: number;
    lon: number;
}

export interface IConsumption {
    nedc: {
        mixedConsumption: string;
        urbanConsumption: string;
        extraUrbanConsumption: string;
        co2: string;
    };
    wltp: {
        highConsumption: string;
        mixedConsumption: string;
        lowConsumption: string;
        co2: string;
        mediumConsumption: string;
        veryHighConsumption: string;
    };
}

export interface IVehiclePrices {
    realPriceExclTax: number;
    discountsInclTax: number;
    finalPriceExclTax: number;
    finalPriceInclTax: number;
    realPriceInclTax: number;
    discountsExclTax: number;
}

export interface IPrices {
    MRIPInclTax: number;
    realPriceExclTax: number;
    basicPriceExclTax: number;
    discountsInclTax: number;
    finalPriceExclTax: number;
    MRIPExclTax: number;
    finalPriceInclTax: number;
    basicPriceInclTax: number;
    realPriceInclTax: number;
    discountsExclTax: number;
}

export interface IPricesWithDates extends IPrices {
    startingDate: string;
    endingDate: string;
}

export enum StockType {
    VN = 'VN',
    VD = 'VD',
}

export interface INameplatePrimaryText {
    primaryText: string;
}

interface IImage {
    type: string;
    url: string;
}

interface IPrice {
    totalPrice: number;
    currency: string;
}

interface IFinancialPricePayment {
    amountInclTax: number;
    amountExclTax: number;
}

interface IFinancialPricePaymentFinance extends IFinancialPricePayment {
    pct: number;
}

interface IFinancialPrice {
    regularPayment: IFinancialPricePayment;
    firstRental: IFinancialPricePayment;
    financialFirstRental: IFinancialPricePaymentFinance;
    financialType: string;
    currency: string;
}

export interface IOfferItemBase extends IPromotionalText {
    cheapestCarImage: IImage;
    cheapestCarCashPrice: IPrice;
    cheapestCarFinancePrice: IFinancialPrice | null;
    bodyStyle: { groupCode: string; title: string };
    model: { groupCode: string; title: string };
    totalVehiclesCount: number;
    nameplateBodyStyleSlug: string;
}

interface ISpecItem {
    value: string;
    label: string;
    key: string;
}

export interface ISpecification {
    id: string;
    description: string;
    title: string;
    specs?: ISpecItem[];
}

interface IFeature {
    label: string;
    key: string;
    order: number;
}

interface IFeatureCategory {
    category: string;
    key: string;
    order: number;
    data: IFeature[];
}

interface ITextWithArguments {
    arguments: string[];
    text: string;
}

interface IPayment {
    amountInclTax: number;
    amountExclTax: number;
}

interface IFinancialPayment extends IPayment {
    pct: number;
}

interface ILegalNotice {
    text: string;
}

interface IColorGroupsAggregation extends IAggregation {
    hexCode: string;
}

interface IPriceAggregation {
    value: number;
}

interface IGuide {
    amountInclExclTax: string[];
    shownTexts: string[];
    amounts: string[];
    shownTextCollections: string[];
    percentages: string[];
}

export interface IVehicleFinancialData {
    regularPayment: ITextWithArguments;
    data: {
        duration: number;
        annualMileage: number;
        regularPayment: IPayment;
        firstRental: IPayment;
        financialFirstRental: IFinancialPayment;
        financialType: string;
        financialProductLabel: string;
        globalMileage: number;
    };
    primarytext: ITextWithArguments;
    legalNotice: ILegalNotice;
    guide: IGuide;
    callToAction: ICallToAction;
}

export interface ICallToAction {
    useWidget?: boolean;
    detailText?: string;
    useDetail?: boolean;
    text?: string;
}

interface IAddressVde {
    zipCode: string;
    city: string;
    line1: string;
    line2: string;
    line3: string;
}

export interface IPointOfSale {
    openingHoursList: {
        [key in Culture]: string;
    };
    siteGeo: string;
    country: Country;
    isEcommerce: boolean;
    address: {
        [key in Culture]: IAddressVde;
    };
    posGroup_id: string;
    distance: number;
    isLeader: boolean;
    coordinates: ICoordinates;
    rrdiShort: string;
    initialRrdi: string;
    rrdi: string;
    posGroup_label: string;
    phoneNumber: string;
    brandCountry: string;
    name: {
        [key in Culture]: string;
    };
    dealerName: string;
    brand: BrandTypes;
    email: string;
    siretNumber: string;
}

export enum EquipmentType {
    EQUIPMENT = 'equipment',
    MANDATORY_OPTION = 'mandatoryOption',
    OPTION = 'option',
}

export interface IEquipmentCategory extends IFeature {
    displayOrder: string;
}

export interface IEquipment extends IEquipmentCategory {
    categoryCode: string;
    significant: boolean;
    type: EquipmentType;
    prices: IPrices;
    superSignificant: boolean;
}

export interface IStockSpecificFields {
    uuid: string;
    rrdi: string;
    caf: string;
    car: string;
    vin: string;
    availabilityDate: string;
    booked: boolean;
    sold: boolean;
    stockType: StockType;
    consumption: IConsumption;
    prices: {
        vehicle: IVehiclePrices;
        bareVersion: IPricesWithDates;
        interiorColour?: IPrices;
        exteriorColour?: IPrices;
        transportFeeInclTax: number;
        vat: number;
        hasMRIP: boolean;
    };
    financialData: IVehicleFinancialData;
    pointsOfSale: IPointOfSale[];
    equipment: IEquipment[];
    distance: number;
    arrivalDate: string;
}

// todo: let it feature grouped -> but exctract to separate file
// this trim item type

export interface IOption {
    id: string;
    title: string;
    prices: IPriceStock[];
    category: string;
    pricesV2: IPriceV2[];
}

export interface IVehicleTransformed {
    id: string;
    externalId: string;
    brand: BrandTypes;
    language: string;
    nameplate: ISpecification;
    images: IImage[];
    fuelCode: string;
    fuel: string;
    stock: boolean;
    detailsAggregated: string;
    detailsOptionsAggregated: string;
    model: ISpecification;
    specPack: ISpecification;
    engine: ISpecification;
    interiorColour: ISpecification;
    exteriorColour: ISpecification;
    lcdv16: string;
    modelYear: string;
    gearbox: ISpecification;
    title: string;
    bodyStyle: ISpecification;
    market: string;
    options: IOption[];
    maxLeadTimeDate: string;
    leadTimeDate: string;
    standardFeatures: IFeatureCategory[];
    extraFields: {
        vehicleUseId: string;
        grBodyStyle: ISpecification;
        promotionalText?: IPromotionalText;
        nameplateBodyStyle: string;
        pricesV2: IPriceV2[];
        grGearbox: ISpecification;
    };
    promotionalText?: IPromotionalText;
    nameplateBodyStyleSlug: string;
    specPackSlug: string;
    exteriorColourSlug: string;
    interiorColourSlug: string;
    engineGearboxFuelSlug: string;
    stockSpecificFields: IStockSpecificFields;
    prices: IPriceStock[];
    dealers: IPointOfSale[];
    orders: {
        status: string;
        uuid: string;
        numCar?: string;
    }[];
    offers?: IOffer[];
    mileage?: string;
    registrationDate?: string;
    requestedStockType?: StockType;
    VDStatus?: VDStatus;
}

export interface IAggregation {
    value: string;
    numberOfVehicles: number;
    labels: {
        [key: string]: number;
    };
}

export interface IAggregations {
    models?: IAggregation[];
    energies: IAggregation[];
    colorGroups: IColorGroupsAggregation[];
    minPrice: IPriceAggregation;
    transmissionTypes: IAggregation[];
    maxPrice: IPriceAggregation;
    bodyStyles?: IAggregation[];
    gearboxTypes: IAggregation[];
    minPriceFinanced: IPriceAggregation;
    maxPriceFinanced: IPriceAggregation;
}

export interface IFiltersResponse {
    allFilters: IAggregations;
    availableFilters: IAggregations;
}

export interface IVehicleTransformedResponse {
    data: {
        totalVehiclesCount: number;
        vehicles: IOfferItemBase;
    };
    statusCode: number;
    trims: IOfferItemBase[];
}

export interface ISaveVehicleResponse {
    success: boolean;
    data: {
        deal: IDeal<IStockCar>;
        financeQuote?: IFinanceQuote;
    };
}
